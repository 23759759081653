.btm-launcher {
  width: 60px;
  height: 60px;
  background-color: #4183c5;
  background-position: center;
  background-repeat: no-repeat;
  position: fixed;
  right: 25px;
  bottom: 45px;
  border-radius: 50%;
  box-shadow: none;
  transition: box-shadow 0.2s ease-in-out;
}

.btm-launcher * {
  cursor: pointer !important;
}

.btm-launcher:before {
  content: "";
  position: relative;
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  transition: box-shadow 0.2s ease-in-out;
}

.btm-launcher .btm-open-icon,
.btm-launcher .btm-closed-icon {
  width: 60px;
  height: 60px;
  position: fixed;
  right: 25px;
  bottom: 45px;
  transition: opacity 100ms ease-in-out, transform 100ms ease-in-out;
}

.btm-launcher .btm-closed-icon {
  transition: opacity 100ms ease-in-out, transform 100ms ease-in-out;
  width: 60px;
  height: 60px;
}

.btm-launcher .btm-open-icon {
  padding: 20px;
  box-sizing: border-box;
  opacity: 0;
}

.btm-launcher.opened .btm-open-icon {
  transform: rotate(-90deg);
  opacity: 1;
}

.btm-launcher.opened .btm-closed-icon {
  transform: rotate(-90deg);
  opacity: 0;
}

.btm-launcher.opened:before {
  box-shadow: 0px 0px 400px 250px rgba(148, 149, 150, 0.2);
}

.btm-launcher:hover {
  box-shadow: 0 0px 27px 1.5px rgba(0, 0, 0, 0.2);
}

.launcher-center {
  margin: 0 50%;
}
