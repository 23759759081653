.fsm-root {
  height: 100vh;
  display: flex;
  /* flex-direction: column; */
}

.fsm-header {
  z-index: 5;
  height: 6vh;
  position: sticky;
  color: #4a4a4a;
  background: #8b05d8;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%);
}

.fsm-content {
  height: 94vh;
  display: flex;
}

.fsm-content-sidebar {
  width: 20%;
  display: flex;
  background: #f0f0f0;
  flex-direction: column;
  transition: .22s transform;
  /* background: linear-gradient(to right,transparent,#aaa); */
}

.fsm-content-chatwindow {
  width: 80%;
  display: flex;
  flex-direction: column;
}

/* fsm header css */
.fsm-header-root {
  height: 100%;
  padding: 0 2%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
}

.fsm-header-title {
  font-size: 20px;
  color: #ffffff;
  font-weight: bold;
}