.sc-message {
  width: 300px;
  margin: auto;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.sc-message--meta {
  color: #C4C4C7;
}

.sc-message--content {
  width: 100%;
  display: flex;
}

.sc-message--content.sent {
  justify-content: flex-end;
}

.sc-message--content.sent .sc-message--avatar {
  display: none;
}

.sc-message--avatar {
  /* background-image: url(https://d13yacurqjgara.cloudfront.net/assets/avatar-default-aa2eab7684294781f93bc99ad394a0eb3249c5768c21390163c9f55ea8ef83a4.gif); */
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  align-self: center;
  margin-right: 15px;
}

/* .sc-message--text {
  padding: 17px 20px;
  border-radius: 6px;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.4;
  white-space: pre-wrap;
  -webkit-font-smoothing: subpixel-antialiased;
  word-wrap: break-word;
  width: 100%;
} */

.sc-message--content.sent .sc-message--text {
  color: white;
  background-color: #4183c5;
  /* max-width: calc(100% - 120px); */
  word-wrap: break-word;
}

.sc-message--content.received .sc-message--text {
  color: #263238;
  background-color: #f4f7f9;
  margin-right: 40px;
}

.sc-message--emoji {
  font-size: 40px;
}

.sc-message--file {
  background: white;
  border: solid 1px #CCCDD1;
  padding: 15px 20px;
  border-radius: 5px;
  display: flex;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.4;
  cursor: pointer;
  text-decoration: none;
}

.sc-message--file p {
  margin: 0px 0px 0px 10px;
  color: rgba(86, 88, 103, 0.6);
}

.sc-message--file .sc-user-input--file-icon:hover path {
  fill: rgba(86, 88, 103, 0.3);
}

@media (max-width: 450px) {
  .sc-message {
    width: 80%;
  }
}

.fsm-message {
  width: 100%;
  display: flex;
  padding: 1.5vh 0;
  box-sizing: border-box;
  justify-content: center;
  border-bottom: 0.5px solid #CCCBCE;
}

.fsm-message-in {
  background-color: #F2F2F2;
}

.fsm-message-out {
}

.fsm-message--text-root {
  width: 75%;
}

.fsm-message--text {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  line-height: 18px;
  white-space: pre-wrap;
  word-wrap: break-word;
  -webkit-font-smoothing: subpixel-antialiased;
  border-radius: 5px;
  position: relative;
  box-sizing: border-box;
}

.fsm-message--intext {
  display: flex;
  justify-content: start;
}

.fsm-message--outtext {
  display: flex;
  align-items: end;
  flex-direction: column;
}

.fsm-message--intext-left {
  display: flex;
  flex-direction: column;
}

.fsm-message--intext-left-width {
  width: 5%;
}

.fsm-message--intext-left-width-regenerate {
  width: 10%;
}

.fsm-message--intext-right {
  display: flex;
  padding: 0 0 0 1%;
  box-sizing: border-box;
  flex-direction: column;
}

.fsm-message--intext-right-width {
  width: 95%;
}

.fsm-message--intext-right-width-regenerate {
  width: 90%;
}

.fsm-message--date {
  font-size: 12px;
  color: #4F4B53;
  font-weight: 400;
  line-height: 13px;
}

.fsm-message--show {
  display: flex;
  cursor: pointer;
  font-size: 12px;
  color: #4183C5;
  justify-content: end;
}

.fsm-message--time-feedback {
  display: flex;
  margin-bottom: 1vh;
  justify-content: start;
}

.fsm-message--feedback-container {
  /* padding: 1%; */
  display: flex;
  align-items: center;
  box-sizing: border-box;
  /* background-color: #ffffff;
  border: 1px solid #BDBDBD; */
}

.fsm-message--feedback-image {
  cursor: pointer;
  margin-right: 20px;
}

.fsm-message--feedback-image-disabled {
  margin-right: 20px;
  pointer-events: none;
}

.fsm-message--button-container {
  display: flex;
  flex-direction: column;
}

.fsm-message--button-didyoumean {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 1vh;
  letter-spacing: 0em;
}

.fsm-message--button {
  display: flex;
  cursor: pointer;
  color: #7500C0;
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  margin-bottom: 1vh;
  width: fit-content;
  letter-spacing: 0em;
  border-radius: 25px;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid #7500C0;
  padding: 12px 20px 12px 16px;
}

.fsm-message--button-image {
  margin-right: 5px;
}

.fsm-message--score-regenerate {
  width: 100%;
  display: flex;
  align-items: center;
}

.fsm-message--score-regenerate-left {
  width: 80%;
}

.fsm-message--score-regenerate-right {
  width: 20%;
  display: flex;
  justify-content: end;
}

.fsm-message--score {
  font-size: 12px;
  font-weight: 400;
  color: #1D1823;
  line-height: 24px;
  margin-bottom: 1vh;
  letter-spacing: 0em;
}

.fsm-message--score-hightlight {
  font-weight: 600;
}

.fsm-message--score-learnmore {
  color: #A100FF;
  cursor: pointer;
}

.fsm-message--regenerate {
  padding: 3%;
  display: flex;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  align-items: center;
  box-sizing: border-box;
  background-color: #ffffff;
  border: 1px solid #BDBDBD;
}

.fsm-message--slider {
  display: flex;
  margin-top: 1vh;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
}

.fsm-message--regenerate-enabled {
  cursor: pointer;
}

.fsm-message--regenerate-disabled {
  pointer-events: none;
}