.fsm-sidebar-root {
  height: 100vh;
  display: flex;
  background: #000000;
  flex-direction: column;
}

.fsm-sidebar-header {
  height: 8vh;
  padding: 0 4%;
  display: flex;
  font-size: 18px;
  color: #ffffff;
  font-weight: 600;
  line-height: 24px;
  /* identical to box height */
  letter-spacing: -0.015em;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(90deg, #460073 0%, #FF50A0 72.4%, #A100FF 100%);
  border-image-slice: 1;
}

.fsm-sidebar-content {
  height: 82vh;
  display: flex;
  padding: 0% 4%;
  box-sizing: border-box;
  flex-direction: column;
}

.fsm-sidebar-footer {
  height: 10vh;
  display: flex;
  padding: 0% 4%;
  color: #B3B2B5;
  margin-top: 0.5vh;
  font-style: italic;
  flex-direction: column;
  box-sizing: border-box;
}

.fsm-sidebar-content-heading {
  font-size: 14px;
  font-weight: 600;
  color: #B3B2B5;
  line-height: 19px;
  font-style: normal;
  margin: 2vh 0 0.5vh 0;
  /* identical to box height */
  letter-spacing: -0.015em;
}

.fsm-sidebar-content-faqs {
  overflow-y: auto;
}

.fsm-sidebar-content-faq-root {
  width: 100%;
  display: flex;
  cursor: pointer;
  flex-direction: column;
}

.fsm-sidebar-content-faq-ques-root {
  width: 100%;
  display: flex;
  padding: 1.5vh 0;
  box-sizing: border-box;
}

.fsm-sidebar-content-faq-ques {
  width: 90%;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 19px;
  font-style: normal;
}

.fsm-sidebar-content-faq-icon {
  width: 10%;
  display: flex;
  justify-content: end;
}

.fsm-sidebar-content-faq-ans {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 19px;
  font-style: normal;
  white-space: pre-wrap;
  margin: 1.5vh 0 1.5vh 0;
  padding-left: 3%;
  box-sizing: border-box;
}

.fsm-sidebar-question {
  margin: 5% 0%;
  cursor: pointer;
  font-size: 16px;
}

.fsm-sidebar-chat-icon {
  margin-right: 10px;
}

.fsm-sidebar-faq-loader {
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fsm-sidebar-datasource-list {
  padding-left: 3%;
  box-sizing: border-box;
}