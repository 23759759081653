.app-root {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  font-size: 14px;
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #858aa8;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #363845;
}

.auth-redirect-root {
  width: 100%;
  height: 96%;
  display: flex;
  font-size: 20px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}